<template>
  <v-sheet class="customer" id="adjustment">
    <v-row style="margin: 0% !important">
      <!-- {{searchParam}} -->
      <v-col md="12" class="px-0 pb-0 d-flex align-center justify-space-between">
        <div>
          <h2 class="custom-header-blue-text m-0">Stock Counts</h2>
          <p class="my-2">
            Please use the table below to navigate or filter the results. You can download the table
            as excel and pdf.
          </p>
        </div>

        <div class="d-flex">
          <div class="mr-3">
            <AutoCompleteInput
              hide-details
              :hideTopMargin="true"
              :items="department"
              id="segment"
              style="width: 200px"
              item-value="value"
              v-model="searchdepartment"
              v-on:change="departmentBy()"
              placeholder="Department Category"
            ></AutoCompleteInput>
          </div>
          <v-btn color="blue darken-4" depressed tile class="white--text" v-on:click="addStock">
            <!-- :to="{ name: 'Count Stock', query: { t: new Date().getTime() } }" -->
            <v-icon left> mdi-plus </v-icon>
            New Stock
          </v-btn>
        </div>
      </v-col>

      <v-col
        md="12"
        class="px-0"
        style="height: calc(100vh - 160px) !important; overflow-y: auto !important"
      >
        <v-simple-table :class="['bt-table', 'adjustment-table']">
          <template v-slot:default>
            <thead style="border: 2px solid #f4f4f4; position: relative; top: 0">
              <tr>
                <th
                  v-for="(stock_h, s_rowIndex) in stock_count_table_headings"
                  :key="'$_' + stock_h.title + '_' + s_rowIndex + 1"
                  :width="stock_h.width"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <span v-if="stock_h.heading === 'selected'">
                          <v-checkbox hide-details color="#0d47a1" class="ma-0 pa-0"></v-checkbox>
                        </span>

                        <span class="text-truncate" v-else>{{ stock_h.title }}</span>
                      </div>
                    </template>
                    <span>{{ stock_h.tooltip }}</span>
                  </v-tooltip>
                </th>
              </tr>
            </thead>
            <tbody v-if="!pageLoading">
              <template v-if="stock?.length">
                <tr
                  v-for="(row, rowIndex) in stock"
                  :class="rowIndex % 2 === 0 && 'blue lighten-5'"
                  class="product-listing-tr cursor-pointer"
                  :key="'$stock_counts_k_' + rowIndex"
                  @click="toDetailPage(row.id)"
                >
                  <td
                    v-for="(r_data, r_key, dataIndex) in row"
                    :key="r_key + '_' + dataIndex + '_' + r_key + dataIndex + '_' + 5"
                    :class="[{ 'cursor-move': r_key === 'drag' }]"
                  >
                    <!-- <div @click.stop v-if="r_key === 'selected'">
                      <v-checkbox
                        hide-details
                        color="#0d47a1"
                        v-model="row[r_key]"
                        class="ma-0 pa-0"
                      ></v-checkbox>
                    </div> -->

                    <div>
                      <span v-if="r_key === 'date'" class="text-capitalize">{{
                        formatedateTime(r_data)
                      }}</span>
                      <span v-if="r_key === 'delivery_date'" class="text-capitalize">{{
                        r_data
                      }}</span>
                      <span v-if="r_key === 'categories'" class="text-capitalize">{{
                        r_data
                      }}</span>
                      <span v-if="r_key === 'type'"
                        ><v-chip
                          variant="tonal"
                          size="small"
                          color="green text-capitalize"
                          text-color="white"
                          >{{ r_data }}</v-chip
                        ></span
                      >

                      <div @click.stop v-if="r_key === 'final_excel_path'">
                        <v-icon v-if="r_data" color="red" size="35" @click="output(r_data)">{{
                          utils.i_download_file
                        }}</v-icon>
                      </div>

                      <div @click.stop v-if="r_key === 'excel_path'">
                        <v-icon size="35" @click="output(r_data)">
                          {{ utils.i_download_file }}
                        </v-icon>
                      </div>
                      <!--   -->
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="9">
                    <p class="m-0 text-center">
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mr-4"
                      />
                      Uhh... There are no stock count at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
        <v-layout v-if="stock?.length" class="light-border-top product-table-bottom mt-4">
          <v-flex md6 align-self-center>
            <label class="btx-label p-4">
              Showing {{ showingFrom }} to {{ showingTo }} of {{ total }} entries</label
            >
          </v-flex>

          <v-flex md6 align-self-center>
            <v-pagination
              v-model="currentPage"
              :length="totalPages"
              :total-visible="5"
              color="blue darken-4"
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>

    <Dialog :dialog="addStockCountDialog">
      <template #title>
        <h1 class="custom-header-blue-text m-0">ADD COUNT STOCK</h1>
      </template>

      <template #subtitle>
        <span>
          Please fill in the information below. The field labels marked with * are required input
          fields.
        </span>
      </template>

      <template #body>
        <v-form
          ref="firstStep"
          v-model="firstStepValid"
          lazy-validation
          v-on:submit.stop.prevent="updateOrCreate"
        >
          <AddStock v-on:form="update" />
        </v-form>
      </template>

      <template #action>
        <v-btn
          :disabled="pageLoading"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          @click="updateOrCreate"
          color="blue darken-4"
          tile
        >
          Save
        </v-btn>
        <v-btn
          :disabled="pageLoading"
          depressed
          tile
          @click="addStockCountDialog = addStockCountDialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>

<script>
import AddStock from "./Add-Stock.vue";
import Dialog from "@/view/components/Dialog";
import { CREATE_STOCK } from "@/core/lib/product.lib";
import { QUERY } from "@/core/services/store/request.module";
import moment from "moment-timezone";
import { SearchEventBus } from "@/core/lib/search.lib";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";

import {
  SET_SEARCH_TITLE,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";

import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";

export default {
  name: "Product-create",
  title: "Create Product",
  data() {
    return {
      pageLoading: false,
      departmentsearch: null,
      searchdepartment: null,
      stock: [],
      department: [],
      productList: [],
      firstStepValid: null,
      searchEnabled: null,
      searchParam: null,
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      data: null,
      addStockCountDialog: false,
      stock_count_table_headings: [
        { tooltip: "Id", title: "#", heading: "", width: 20 },
        //{ tooltip: "Select", title: "", heading: "selected", width: 50 },
        { tooltip: "Date", title: "Date", heading: "date", width: 150 },
        { tooltip: "Reference No", title: "Reference No", heading: "delivery_date", width: 150 },
        {
          tooltip: "Type",
          title: "Type",
          heading: "type",
          width: 100,
        },
        {
          tooltip: "Department Category",
          title: "Department Category",
          heading: "category",
          width: 150,
        },
        { tooltip: "Output", title: "Output", heading: "output", width: 40 },
        {
          tooltip: "Final Stock",
          title: "Final Stock",
          heading: "final_excel_path",
          width: 40,
        },
      ],

      stock_count_table_data: [
        {
          id: 1,
          //selected: false,
          date: "14/08/2023",
          remark: "ref123",
          type: "Full",
          category: "Kitchen",
          output: "",
          final_stock: "",
        },
        {
          id: 2,
          //selected: true,
          date: "15/08/2023",
          remark: "ref456",
          type: "Partial",
          category: "Bar Alcoholic",
          output: "",
          // final_stock: "",
        },
      ],
      utils: {
        i_download_file: "mdi-file-download",
        i_download_file_outline: "mdi-file-download-outline",
      },
    };
  },
  components: { AddStock, Dialog, AutoCompleteInput },
  computed: {},
  watch: {
    currentPage() {
      this.getData();
    },
  },
  methods: {
    departmentBy() {
      this.departmentsearch = this.searchdepartment;
      this.getData();
    },
    getDepartment() {
      this.$store
        .dispatch(QUERY, {
          url: "data",
        })
        .then((data) => {
          this.department = data.departments;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    addStock() {
      this.addStockCountDialog = true;
    },
    formatedateTime(param) {
      return moment(param).format("DD/MM/YYYY ");
    },
    output(url) {
      console.log(url);
      window.location.href = url;
    },
    getData(search = null) {
      this.$store
        .dispatch(QUERY, {
          url: "stock-count",
          data: {
            current_page: this.currentPage,
            search,
            departmentsearch: this.departmentsearch,
          },
        })
        .then((data) => {
          this.stock = data.data;
          this.currentPage = data.currentPage;
          this.showingFrom = data.showingFrom;
          this.showingTo = data.showingTo;
          this.total = data.totalRows;
          this.totalPages = data.totalPages;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    update(param) {
      this.data = param;
    },

    async updateOrCreate() {
      const _this = this;
      if (!_this.$refs.firstStep.validate()) {
        return false;
      }
      // const formErrors = _this.validateForm(_this.$refs.productAddForm);

      try {
        _this.pageLoading = true;
        console.log(_this.data, "data");

        if (_this.$route.params.id) {
          _this.product.image = this.product?.profile_logo?.id;
          console.log(_this.product.image);
          const product = await CREATE_STOCK(_this.$route.params.id, _this.product);
          console.log(product);

          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Stock Updated Successfully." },
          ]);
        } else {
          const customer = await CREATE_STOCK(_this.data);
          console.log(customer);
          _this.$router.replace({
            name: "Stock Count",

            query: { t: new Date().getTime() },
          });
          this.addStockCountDialog = false;
          this.getData();
          _this.$router.replace({
            name: "Stock Count",

            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Stock Count Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
        this.$refs.firstStep.reset(); // This will clear that form
      }
    },
    toDetailPage(id) {
      //console.log('id',{ id });
      this.$router.push({
        name: "Finalize Count",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
  },
  mounted() {
    this.getDepartment();
    this.getData();

    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "Stock Counts");
    let _this = this;
    SearchEventBus.$on("start-search", (argument) => {
      _this.searchEnabled = false;
      _this.searchParam = argument;
      if (_this.searchParam) {
        _this.searchEnabled = true;
      }
      this.getData(argument);
    });
    SearchEventBus.$emit("search-template", true);
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
    SearchEventBus.$emit("search-template", false);
  },
};
</script>
